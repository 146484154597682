<template>
  <section class="architecture-container row-10 text-green fixOverflow">
    <div class="inset offset-1 col-8 md:offset-3 md:col-4">

      <div class="md:text-center">
        <h2 class="architecture-title" v-text="payload.title" />
        <div class="architecture-text">
          <div v-html="payload.text" />
          <div class="architecture-quote mt-8">
            <div v-html="payload.quote" />
            <div class="signature" v-html="payload.signature" />
          </div>
        </div>
      </div>

      <div class="relative mt-12">
        <swiper
          v-if="payload.slider.length"
          :loop="true"
          :speed="750"
          :space-between="100"
          @swiper="onSwiper"
        >
          <swiper-slide v-for="slide in payload.slider" :key="slide.id">
            <div class="aspect a32 cover mb-4">
              <Image :src="slide.bild" />
            </div>
            <div class="caption" v-html="slide.bildtext" />
          </swiper-slide>
        </swiper>
        <div class="nextSlide" @click="swiper.slideNext()" />
        <div class="prevSlide" @click="swiper.slidePrev()" />
      </div>

    </div>
  </section>
</template>

<script>

import { Swiper, SwiperSlide } from 'swiper/vue';
import SwiperCore, {
  Autoplay, A11y, Pagination, Navigation,
} from 'swiper';

SwiperCore.use([Autoplay, A11y, Pagination, Navigation]);

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    payload: Object,
  },
  data() {
    return {
      swiper: {},
    };
  },
  methods: {
    onSwiper(swiper) { this.swiper = swiper; },
  },
};
</script>

<style lang="scss" scoped>

</style>
